<template>
  <div>
    <div
      style="display: flex; align-items: center"
      v-if="checkIfConnectedToXero.status"
    >
      <feather-icon
        style="width: 15px; height: 15px; color: indianred !important"
        icon="AlertTriangleIcon"
      ></feather-icon>
      <p style="color: indianred; font-weight: 600">
        You haven't connected xero yet!
      </p>
      <a
        :href="checkIfConnectedToXero.href"
        style="
          color: rgba(var(--vs-primary), 1) !important;
          font-weight: 600;
          margin-left: 5px;
          cursor: pointer;
        "
        >Connect Now</a
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    checkIfConnectedToXero() {
      if (localStorage.getItem("xeroConnectionStatus") === "true" || this.$store.state.AppActiveUser.xeroConnectionStatus) {
        let href = "#";
        return { status: false, href };
      } else {
        const userType = this.$store.state.AppActiveUser.userType;
        let href = "";
        if (userType === "superAdmin") {
          href = `${process.env.VUE_APP_BASE_API_URL}/xero/connect?backurl=${process.env.VUE_APP_CLIENT_URL}/super-admin/dashboard`;
        } else {
          href = `${process.env.VUE_APP_BASE_API_URL}/xero/connect?backurl=${process.env.VUE_APP_CLIENT_URL}/admin/dashboard`;
        }
        return { status: true, href };
      }
    },
  },
};
</script>