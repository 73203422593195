import store from "@/store/store";

const navArray = [
  {
    url: "/admin/dashboard",
    name: "Dashboard",
    slug: "dashboard",
    icon: "MonitorIcon",
    i18n: "Dashboard"
  }
];

const permissions = store.state.AppActiveUser.permissions;

if (permissions) {
  // if (permissions.viewEmailTemplates)
  //   navArray.push({
  //     url: "/admin/email-templates",
  //     name: "Email Templates",
  //     slug: "email-templates",
  //     icon: "MailIcon",
  //     i18n: "Email Templates"
  //   });

  // if (permissions.viewPageManagement)
  //   navArray.push({
  //     url: "/admin/pages",
  //     name: "Page Management",
  //     slug: "page-management",
  //     icon: "FileIcon",
  //     i18n: "Page Management"
  //   });

  if (permissions.viewClinicManagement)
    navArray.push({
      url: "/admin/clinics",
      name: "Clinic Management",
      slug: "clinic-management",
      icon: "HomeIcon",
      i18n: "Clinic Management"
    });

  if (permissions.viewFranchiseManagement)
    navArray.push({
      url: "/admin/franchises",
      name: "Franchise Management",
      slug: "franchise-management",
      icon: "HomeIcon",
      i18n: "Franchise Management"
    });

  if (permissions.viewDoctorManagement)
    navArray.push({
      url: "/admin/doctors",
      name: "FranchiseDoctors",
      slug: "franchise-doctors-management",
      i18n: "Doctors Management",
      icon: "HeadphonesIcon"
    });

  if (permissions.viewNurseManagement)
    navArray.push({
      url: "/admin/nurses",
      name: "Nurses Management",
      slug: "pages-register-nurse",
      i18n: "Nurses Management",
      icon: "UserIcon"
    });

  if (permissions.viewPatientManagement)
    navArray.push({
      url: "/admin/patients",
      name: "Patient Management",
      slug: "patient-management",
      icon: "UsersIcon",
      i18n: "Patient Management"
    });

  if (permissions.viewDoctorAggregatedReport)
    navArray.push({
      url: "/admin/doctor-aggregated-report",
      name: "Doctor Aggregated Report",
      slug: "doctor-aggregated-report",
      i18n: "Doctor Aggregated Report",
      icon: "BookOpenIcon"
    });

  if (permissions.viewInvoice)
    navArray.push({
      url: "/admin/invoice",
      name: "Invoice",
      slug: "invoice",
      i18n: "Invoice",
      icon: "CreditCardIcon"
    });

  if (permissions.viewPushNotification)
    navArray.push({
      url: "/admin/push-notification",
      name: "Push Notification",
      slug: "push-notification",
      i18n: "Push Notification",
      icon: "ListIcon"
    });

  if (permissions.viewPairDevice)
    navArray.push({
      url: "/admin/pair-device",
      name: "Pair Device",
      slug: "pair-device",
      i18n: "Pair Devices",
      icon: "SmartphoneIcon"
    });

  if (permissions.viewTreatmentTypes)
    navArray.push({
      url: "/admin/treatment-types",
      name: "Treatment Types",
      slug: "treatment-types",
      icon: "ServerIcon",
      i18n: "Treatment Types"
    });
}

navArray.push({
  header: "Store Management",
  icon: "LayersIcon",
  i18n: "Store Management",
  items: [
    {
      url: "/admin/store/categories",
      name: "Category Management",
      slug: "category-management",
      icon: "ShoppingCartIcon",
      i18n: "Category Management"
    },
    {
      url: "/admin/store/products",
      name: "Product Management",
      icon: "InboxIcon",
      slug: "products",
      i18n: "Product Management"
    },
    {
      url: "/admin/store/suppliers",
      name: "Supplier Management",
      icon: "UsersIcon",
      slug: "suppliers",
      i18n: "Supplier Management"
    },
    {
      header: "Product Management",
      icon: "LayersIcon",
      i18n: "Product Management",
      items: [
        {
          url: "/admin/shop",
          name: "Shop",
          slug: "shop",
          i18n: "Shop",
          icon: "HomeIcon"
        },
        {
          url: "/admin/coming-soon",
          name: "Shop",
          slug: "coming-soon",
          i18n: "Shop",
          icon: "HomeIcon"
        },
        {
          url: "/admin/orders",
          name: "Orders",
          slug: "orders",
          i18n: "Orders",
          icon: "ListIcon"
        }
      ]
    }
  ]
});
export default navArray;
